h1 {
  font-size: 2em;
}

.product-title {
  font-weight: bold;
  font-size: 1.2em;
}

.product-id {
  font-size: .8em;
  margin: 1em 0;
}

.product-edit-icon {
  opacity: .7;
  position: absolute;
  right: 2.3rem;
  top: .4rem;
}

.product-edit-icon:hover {
  opacity: 1;
}

.navbar, .navbar-end, .navbar-menu, .navbar-start {
  align-items: stretch !important;
  display: flex !important;
}

.navbar-menu {
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.navbar-item {
  display: flex !important;
}

.navbar-item img {
    max-height: 2.75rem;
}

.navbar-end {
  justify-content: flex-end !important;
  margin-left: auto !important;
}

.features {
  padding-bottom: 3em;
}

body {
  visibility: visible !important;
}


.auth form {
  margin-top: 1em;
  max-width: 50%;
}

.buttons {
  margin-left: 1em;
}

/* .hero.is-primary {
  background-color: #ffffff;
} */

.has-bg-img {
  background: url('/public/tips-background-hero-pattern.png');
  background-size: cover;
}

.button.is-primary {
  background-color: #44bac2;
}


/* Below this line - Image CSS from original page */

.thumbnail {
  position: relative;
  width: 200px;
  height: 200px;
  overflow: hidden;
  }
  .thumbnail img {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  }
.thumbnail img.portrait {
  width: 100%;
  height: auto;
}


.panel-body { height:500px; width: 250px; padding-bottom: 50px; }

    /* Modify image panel sizing */

.panel-default {
  border-color: #ddd;
  padding-bottom: 40px;
}

input[type='checkbox'] {
  -webkit-appearance: none;
  width:20px;
  height:20px;
  background: white;
  border-radius: 5px;
  border: 2px solid black;
  margin-right: 10px;
}

input[type='checkbox']:checked {
  background: #44bac2;
}

input[type='radio'] {
  -webkit-appearance: none;
  width:20px;
  height:20px;
  background: white;
  border-radius: 10px;
  border: 2px solid black;
  margin-right: 10px;
}

input[type='radio']:checked {
  background: #44bac2;
}

@media screen and (max-width:1023px) {
  .navbar-dropdown.is-boxed {
    border-radius: 6px;
    border-top: none;
    box-shadow: 0 8px 8px rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.1);
    display: block;
    opacity: 0;
    pointer-events: none;
    top: calc(100% + (-4px));
    transform: translateY(-5px);
    transition-duration: 86ms;
    transition-property: opacity,transform;
  }
  .navbar-dropdown {
    background-color: #fff;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 2px solid #dbdbdb;
    box-shadow: 0 8px 8px rgba(10,10,10,.1);
    display: none;
    font-size: .875rem;
    left: 0;
    min-width: 100%;
    position: absolute;
    top: 100%;
    z-index: 20;
  }
}

a {
  text-decoration: none !important;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 0px solid #ebebeb;
  border-radius: 0.25rem;
  color: #ff9900;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #00a651;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

table, td, th, tr {
  border: 1.75px solid #000 !important;
}

.is-horizontal-center {
  justify-content: center;
}